import React from "react"
//import "../../styles/mystyles.scss"

import parse from "html-react-parser"

class SuppDetail extends React.Component {
  render() {
    const detail = this.props.suppPage.suppText
    const playTitle = this.props.playTitle
    return (
      <>
        <div className = "block">
          <span className="title is-4 has-text-sibblack is-italic">
            {playTitle}
          </span>
          <span className="title is-4 has-text-sibblack">
            &nbsp;- {detail.label}
          </span>
        </div>
        <div className="content">{parse(detail.text)}</div>
      </>
    )
  }
}

export default SuppDetail
