import React from "react"

import SuppIndex from "./suppindex"
import SuppDetail from "./suppdetail"

class SuppPage extends React.Component {
  /*constructor(props) {
    super(props)
  }*/

  render() {
    return (
      <div className="columns outer-keypage-container mb-2">
        <div className="column py-2 is-one-quarter supp-page-menu">
            <SuppIndex
              playId={this.props.playId}
              suppPage={this.props.suppPage}
            />
        </div>
        <div className="column py-2 px-2">
            <SuppDetail
              playId={this.props.playId}
              playTitle={this.props.playTitle}
              suppPage={this.props.suppPage}
            />
        </div>
      </div>
    )
  }
}

export default SuppPage
