import React from "react"
//import "../../styles/mystyles.scss"
import { navigate } from "gatsby"

class SuppIndex extends React.Component {
  constructor(props) {
    super(props)
    this.navTo = this.navTo.bind(this)
  }

  navTo = (e, dest) => {
    e.preventDefault()
    var destNow = dest
    if (e.target.value) {
      destNow = e.target.value
    }
    navigate(`/plays/${this.props.playId}/supp/${destNow}`)
  }

  render() {
    const suppIndex = this.props.suppPage.suppIndex
    const currentSuppId = this.props.suppPage.suppText.suppId
    const suppSections = ["texts", "info"]
    return (
      <>
        <div className="is-hidden-mobile">
          <SuppNavMenu
            suppIndex={suppIndex}
            currentSuppId={currentSuppId}
            suppSections={suppSections}
            navTo={this.navTo}
          />
        </div>
        <div className="is-hidden-tablet container">
          <SuppDropdownMenu
            suppIndex={suppIndex}
            currentSuppId={currentSuppId}
            suppSections={suppSections}
            navTo={this.navTo}
          />
        </div>
      </>
    )
  }
}

const SuppNavMenu = props => {
  const suppIndex = props.suppIndex
  const currentSuppId = props.currentSuppId
  const suppSections = props.suppSections
  var suppKey = 0
  return (
    <aside className="menu">
      {suppSections.map(supp => {
        return (
          <div className="mb-5" key={`suppkey_index_${suppKey++}`}>
            <p className="menu-label">{suppIndex[supp].label}</p>
            <ul className="menu-list">
              {suppIndex[supp].entries.map(entry => {
                return (
                  <li key={`suppkey_index_${suppKey++}`}>
                    <a
                      className={`${
                        entry.suppId === currentSuppId ? "is-active" : ""
                      }`}
                      href="/"
                      onClick={e => props.navTo(e, entry.suppId)}
                    >
                      {entry.label}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </aside>
  )
}

const SuppDropdownMenu = props => {
  const suppIndex = props.suppIndex
  const currentSuppId = props.currentSuppId
  const suppSections = props.suppSections
  var suppKey = 0
  return (
    <div className="columns is-mobile is-vcentered">
      <div className="column has-text-centered">
        <h6 className="title is-6 has-text-sibblack">Currently Reading: </h6>
      </div>
      <div className="column">
        <div className="select is-sibblack is-rounded">
          <select
            onChange={e => props.navTo(e)}
            onBlur={e => props.navTo(e)}
            defaultValue={currentSuppId}
          >
            {suppSections.map(supp => {
              return suppIndex[supp].entries.map(entry => {
                return (
                  <option
                    key={`suppkey_index_${suppKey++}`}
                    value={entry.suppId}
                  >
                    {entry.label}
                  </option>
                )
              })
            })}
          </select>
        </div>
      </div>
    </div>
  )
}

export default SuppIndex
