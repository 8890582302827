import React from "react"
import { connect } from "react-redux"

import Layout from "../components/common/layout/layout"
import PlayNav from "../components/product/sibplay/playnav/playnav"

import SuppPage from "../components/product/sibplay/supp/supppage"
import { setLastSuppForPlay } from "../state/app"

import Seo from "../components/common/layout/seo"

const SuppContainer = (props) => {
  const { pageContext } = props
  const { playId, suppliment, playTitle, manifest } = pageContext
  props.dispatch(setLastSuppForPlay(playId, suppliment.suppText.suppId))
  return (
    <Layout>
      <Seo title={playTitle} />
        <PlayNav
          playId={playId}
          playTitle={playTitle}
          section="analysis"
          manifest={manifest}
        />
      <div className="section py-1">
        <div className="container">
        <SuppPage playId={playId} playTitle={playTitle} suppPage={suppliment} />
        </div>
      </div>
    </Layout>
  )
}

export default connect(
  (state) => ({
    lastSupps: state.app.lastSupps,
  }),
  null
)(SuppContainer)
